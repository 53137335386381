<template>
    <el-drawer direction="rtl" :visible="vis" :before-close="cancelDrawer">
            <el-form :inline="true" :rules="rules" :model="medicalForm" ref="medicalForm" label-width="120px">
                <el-form-item label="发票代码" prop="fpdm">
                    <el-input placeholder="请输入交款人" v-model="medicalForm.fpdm" maxlength="50" />
                </el-form-item>
                <el-form-item label="发票号码" prop="fphm">
                    <el-input placeholder="请输入交款人" v-model="medicalForm.fphm" maxlength="50" />
                </el-form-item>
                <el-form-item label="交款人" prop="jkr">
                    <el-input placeholder="请输入交款人" v-model="medicalForm.jkr" maxlength="50" />
                </el-form-item>
                <el-form-item label="医院名称" prop="skdw">
                    <el-input placeholder="请输入医院名称" v-model="medicalForm.skdw" maxlength="50" />
                </el-form-item>
                <el-form-item label="开票日期" prop="kprq">
                    <el-date-picker
                    v-model="medicalForm.kprq"
                    type="date"
                    placeholder="开票日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    />
                </el-form-item>
                <el-form-item label="金额合计（大写）" prop="jehjdx">
                    <el-input placeholder="请输入金额合计（大写）" v-model="medicalForm.jehjdx" maxlength="50" />
                </el-form-item>
                <el-form-item label="金额合计（小写）" prop="jehjxx">
                    <el-input placeholder="请输入金额合计（小写）" v-model="medicalForm.jehjxx" maxlength="50" />
                </el-form-item>
                <el-row style="text-align: center;">
                    <el-form-item >
                        <el-button type="primary"  @click="save">保存</el-button>
                        <el-button @click="closeDraw">取消</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-drawer>
</template>
<script>
    export default {
        props:{
            vis: {
                type: Boolean,
            },
            cancelDrawer: {
                type: Function,
                default: () => { },
            },
        },
        data(){
            return {
                rules:{
                    fpdm: {required:true,message:"发票代码不能为空"},
                    fphm: {required:true,message:"发票号码不能为空"}
                },
                medicalForm:{
                    id:'',
                    companyId:'',
                    jkr:'',
                    fpdm:'',
                    fphm:'',
                    skdw:'',
                    jehjdx:'',
                    jehjxx:'',
                    kprq:''
                }
            }
        },
        methods: {
            initAdd(){
                this.medicalForm = {
                    id:'',
                    companyId:'',
                    jkr:'',
                    fpdm:'',
                    fphm:'',
                    skdw:'',
                    jehjdx:'',
                    jehjxx:'',
                    kprq:''
                }
            },
            save() {
                if (this.medicalForm.id){
                    this.$store.dispatch("EditMedical",
                        {
                            ...this.medicalForm,
                        }
                    ).then(res => {
                        if (res.success) {
                            einvAlert.success("提示","保存成功");
                            this.$parent.medicalDrawerVis = false;
                            this.$parent.getList()
                        } else {
                            einvAlert.error("提示",res.msg)
                        }
                    }).catch(err => {
                        einvAlert.error("提示","服务运行异常")
                    })
                } else{
                    this.$refs.medicalForm.validate((valid) => {
                        if (valid){
                            this.medicalForm.companyId = this.getToken('companyId')
                            this.$store.dispatch("AddMedical",
                                {
                                    ...this.medicalForm,
                                }
                            ).then(res => {
                                if (res.success) {
                                    einvAlert.success("提示","保存成功");
                                    this.$parent.medicalDrawerVis = false;
                                    this.$parent.getList()
                                } else {
                                    einvAlert.error("提示",res.msg)
                                }
                            }).catch(err => {
                                einvAlert.error("提示","服务运行异常")
                            })
                        }
                    })
                }
            },
            closeDraw() {
                this.$parent.medicalDrawerVis = false;
            },
            load(id){
                this.$store.dispatch("LoadMedical",{
                    companyId: this.getToken('companyId'),
                    id: id
                }).then(res => {
                    if (res.success) {
                        this.medicalForm = res.data;
                    } else {
                        einvAlert.error("提示",res.msg);
                    }
                }).catch(err => {
                    einvAlert.error("提示",err);
                })
            }
        }
    }
</script>